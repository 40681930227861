/* ==========================================================================
  Settings: Colors
  Only define colors here. Don't use sass color functions, stick to hex values,
  or the color component in styleguide won't regenerate automatically anymore.
 ========================================================================== */

/* Grays
 ========================================================================== */

$color-gray-50: #EDEFF2;
$color-gray-100: #D6DEE3;
$color-gray-150: #BAC7D1;
$color-gray-200: #9CAFBD;
$color-gray-300: #7894A7;
$color-gray-400: #557891;
$color-gray-500: #45647B;
$color-gray-600: #385265;
$color-gray-700: #2B414F;
$color-gray-800: #1D2B35;
$color-gray-900: #0F171D;
$color-gray-1000: #000000;

 /* Key colors
  ========================================================================== */

$color-blue: #30C2FF;

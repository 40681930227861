/* ==========================================================================
  c-nav-mobile 
   ========================================================================== */

.c-nav-mobile {
  
  background: #EEE;
  @media (prefers-color-scheme: dark) {
    background: #0B101C;
  }
  position: fixed;
  top: 6.4rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.c-nav-mobile a {
  margin: 0 auto;
  border-bottom: 1px solid rgba(#000,0.13);
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid rgba(#FFF,0.13);
  }
  
  padding: 0.6rem 1.6rem;
  font-size: 4.8rem;
  display: block;
  
  
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #FFF;
  }
  font-weight: 500;
  font-family: $g-code-font-family;
}

.c-nav-mobile a:hover,
.c-nav-mobile a:focus {
  background: rgba(#000,0.1);
  @media (prefers-color-scheme: dark) {
    background: rgba(#FFF,0.1);
  }
}

.c-nav-mobile a:last-child {
  border: none;
}

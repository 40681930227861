/* ==========================================================================
  Settings: Fonts
   ========================================================================== */

@font-face {
  font-family: "Untitled Sans";
  font-weight: 400;
  src: url('../fonts/untitled-sans-web-regular.woff2') format('woff2'),
       url('../fonts/untitled-sans-web-regular.woff2') format('woff')
}

@font-face {
  font-family: "Untitled Sans";
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/untitled-sans-web-regular-italic.woff2') format('woff2'),
       url('../fonts/untitled-sans-web-regular-italic.woff2') format('woff')
}

@font-face {
  font-family: "Untitled Sans";
  font-weight: 500;
  src: url('../fonts/untitled-sans-web-medium.woff2') format('woff2'),
       url('../fonts/untitled-sans-web-medium.woff2') format('woff')
}

@font-face {
  font-family: "Untitled Sans";
  font-weight: 700;
  src: url('../fonts/untitled-sans-web-bold.woff2') format('woff2'),
       url('../fonts/untitled-sans-web-bold.woff2') format('woff')
}

@font-face {
  font-family: "Atlas Typewriter Web";
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  src: url('../fonts/AtlasTypewriter-Regular-Web.woff2') format('woff2'),
       url('../fonts/AtlasTypewriter-Regular-Web.woff') format('woff');
}
/* ==========================================================================
  c-nav-toggle
   ========================================================================== */

.c-nav-toggle {
  padding: 1rem 0;
}

.c-nav-toggle button {
  background: none;
  border: none;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  padding: 0;
}

.c-nav-toggle button svg {
  margin: 0 auto;
}

.c-nav-toggle button svg path {
  fill: #000;
}

.c-nav-toggle button svg line {
  stroke: #000;
}

@media (prefers-color-scheme: dark) {
  .c-nav-toggle button svg path {
    fill: #FFF;
  }
  .c-nav-toggle button svg line {
    stroke: #FFF;
  }
}

/* ==========================================================================
  c-hr
   ========================================================================== */

.c-hr {
  border: none;
  border-top: 1px solid rgba(#000,0.25);
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid rgba(#FFF,0.25);
  }
}

/* ==========================================================================
   Elements: Base
   ---
   Only use elements without selectors when really necessary.
   ========================================================================== */

/* Elements
   ========================================================================== */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 54.16%; // 13px
  @media (min-width: $g-bp3) {
    font-size: 58.33%; // 14px
  }
  @media (min-width: $g-bp4) {
    font-size: 62.5%; // Base 10
  }
}

body {
  font-family: $g-base-font-family;
  font-size: $g-base-font-size;
  line-height: $g-base-line-height;
  color: #333;
  background: #FFF;
  @media (prefers-color-scheme: dark) {
    color: #A0A4AE;
    background: #1B2130;
  }
  font-feature-settings: "liga", "kern";
}

img,
video {
  max-width: 100%;
}

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

a {
  color: $color-primary;
  text-decoration: none;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: $g-headings-font-weight;
}

abbr[title] {
  text-decoration: none;
  opacity: 0.5;
}

img {
  max-width: 100%;
}

@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(0.9) contrast(1.1);
  }
}
/* ==========================================================================
  c-content
  ---
  Usage: wrap a .c-content class around content to turn it into rich text.

  .c-h1, .c-h2, .c-body-1, c-body 2
  ---
  Usage: use these classes to style individual components like their rich text equivalent
   ========================================================================== */


.c-h1, .c-content h1,
.c-h2, .c-content h2,
.c-h3, .c-content h3,
.c-h4, .c-content h4 {
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #FFF;
  }  
}

.c-h1, .c-content h1 {
  font-family: $g-headings-font-family;
  font-weight: $g-headings-font-weight;
  font-size: 3.2rem;
  margin: 0 0 3.2rem;
  line-height: 1.25;
}

.c-h2, .c-content h2 {
  font-family: $g-headings-font-family;
  font-weight: $g-headings-font-weight;
  font-size: 2.4rem;
  margin: 0 0 1.6rem;
  line-height: 1.25;
}

.c-h3, .c-content h3 {
  font-family: $g-headings-font-family;
  font-weight: $g-headings-font-weight;
  font-size: 2.1rem;
  margin: 0 0 1.5rem;
  line-height: 1.25;
}

.c-h4, .c-content h4 {
  font-family: $g-base-font-family;
  font-size: $g-base-font-size;
  font-weight: $g-headings-font-weight;
  margin: 0 0 1.5rem;
  font-weight: 500;
}

.c-body-1 {
  font-size: $g-base-font-size;
  margin: 1.5rem 0;
}

.c-body-2 {
  font-size: 1.3rem;
  margin: 1.3rem 0;
}

.c-content {

  p {
    margin: 1.5rem 0;
  }
  
  b, strong {
    color: #000;
    @media (prefers-color-scheme: dark) {
      color: #FFF;
    }
  }

  dl {
    border: 1px solid $color-gray-100;
    border-radius: .3rem;
    overflow: hidden;
    padding: 1.2rem;
    margin: 1.5rem 0;
    dt {
      float: left;
      width: 30%;
      clear: left;
      font-weight: $g-headings-font-weight;
    }
    dd {
      float: left;
      width: 70%;
    }
  }

  ul, ol {
    padding-left: 3rem;
    margin: 1.5rem 0;
    ul, ol {
      margin: 0;
    }
  }

  ul {
    list-style: square;
  }

  ol {
    list-style: decimal;
  }

  blockquote {
    font-style: italic;
    padding-left: 1.5rem;
    margin-left: 0.2rem;
    border-left: .4rem solid $color-gray-300;
  }

  table {
    width: 100%;
    margin: 1.5rem 0;
  }

  table td,
  table th {
    padding: 0.8rem;
  }

  table th {
    border-bottom: 0.2rem solid $color-gray-200;
    font-weight: $g-headings-font-weight;
    text-align: left;
  }

  table td {
    border-bottom: 1px solid $color-gray-100;
  }

  strong {
    font-weight: $g-headings-font-weight;
  }
  
  a {
    border-bottom: 1px solid currentColor;
  }

  .c-btn {
    text-decoration: none;
  }

  pre {
    background: $color-gray-100;
    padding: 1.5rem;
    margin: 0 0 1rem;
    white-space: pre-wrap;
  }

  pre, code {
    font-family: $g-code-font-family;
  }
  
  code {
    font-size: 90%;
  }

}

/* ==========================================================================
  c-intro
   ========================================================================== */

.c-intro {
  font-size: 1.9rem;
  line-height: 1.7;
  font-family: $g-code-font-family;
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #FFF;
  }

}

.c-intro--lg {
  font-size: 180%;
}
/* ==========================================================================
  Utility: spacer
   ========================================================================== */

/* Resets
   ========================================================================== */

.u-m-0 {
  margin: 0 !important;
}

.u-p-0 {
  padding: 0 !important;
}

/* Variables
   ========================================================================== */

$g-spacer-unit: 0.8rem !default;

/* All
   ========================================================================== */

.u-spacer-s {
  margin: $g-spacer-unit !important;
}

.u-spacer {
  margin: $g-spacer-unit * 2 !important;
}

.u-spacer-l {
  margin: $g-spacer-unit * 3 !important;
}

/* Left
   ========================================================================== */

.u-spacer-left-s {
  margin-left: $g-spacer-unit !important;
}

.u-spacer-left {
  margin-left: $g-spacer-unit * 2 !important;
}

.u-spacer-left-l {
  margin-left: $g-spacer-unit * 3 !important;
}

/* Right
   ========================================================================== */

.u-spacer-right-s {
  margin-right: $g-spacer-unit !important;
}

.u-spacer-right {
  margin-right: $g-spacer-unit * 2 !important;
}

.u-spacer-right-l {
  margin-right: $g-spacer-unit * 3 !important;
}

/* Top
   ========================================================================== */

.u-spacer-top-s {
  margin-top: $g-spacer-unit !important;
}

.u-spacer-top {
  margin-top: $g-spacer-unit * 2 !important;
}

.u-spacer-top-l {
  margin-top: $g-spacer-unit * 3 !important;
}

/* Bottom
   ========================================================================== */

.u-spacer-bottom-s {
  margin-bottom: $g-spacer-unit !important;
}

.u-spacer-bottom {
  margin-bottom: $g-spacer-unit * 2 !important;
}

.u-spacer-bottom-l {
  margin-bottom: $g-spacer-unit * 3 !important;
}

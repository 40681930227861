/* ==========================================================================
  c-album-card
   ========================================================================== */

.c-album-card {
  text-align: center;
  grid-column-end: span 4;
  @media (min-width: $g-bp3) {
    grid-column-end: span 3;
  }
}

.c-album-card__heading {
  font-size: 1.6rem;
  font-weight: 500;
  
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #FFF;
  }

  margin: 0 0 0.4rem;
}

.c-album-card__body {
  padding: 1.6rem;
}

.c-album-card__meta {
  opacity: 0.6;
  margin: 0.3rem 0;
  text-transform: uppercase;
  font-size: 1.3rem;
}

.c-album-card__category {
  display: inline-block;
  border-radius: 1.6rem;
  padding: 0.2rem 0.8rem;
  margin: 0.4rem;
  font-size: 1.2rem;

  background: rgba(#000,0.15);
  @media (prefers-color-scheme: dark) {
    background: rgba(#FFF,0.15);
  }
  
}
/* ==========================================================================
  Utility: display
  ===
  Display-related utility classes
   ========================================================================== */

/* Utilities
   ========================================================================== */

.u-d-block {
  display: block;
}

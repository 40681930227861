/* ==========================================================================
  c-lightbox
   ========================================================================== */

.c-lightbox-backdrop {
    position: fixed;
    background: rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.c-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.c-lightbox__inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.c-lightbox__image {
    margin: auto;
    display: block;
}

.c-lightbox .c-lightbox__button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 3;
  background: #FFF;
}
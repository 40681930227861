/* ==========================================================================
  c-brand
   ========================================================================== */

.c-brand p {
  font-size: 2.2rem;
  font-weight: 500;
}

.c-brand a {
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #FFF;
  }
  
  padding: 0.8rem 0;
  display: inline-block;
  transition: 0.17s background ease-in-out;
}

.c-brand a:hover {
  background: rgba(255,255,255,0.1);
}

.c-brand a:focus {
  background: rgba(255,255,255,0.2);
}